<template>
<div>

<section class="section">
  <div class="container has-text-centered">
    <h1 class="title" style="margin-bottom: 30px;">Reset Your Password</h1>
  </div>

  <div class="container">

    <div class="columns is-centered">
      <div class="column is-5">
        <div class="box">

          <Field 
            v-bind.sync="email" 
            :label="'Enter Your Registered Email Address'" 
            :placeholder="'Email'"
            :func="'email'" 
          />

          <div class="notification is-success" v-if="success">
            <p class="has-text-left">
              {{ success }}
            </p>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <div class="buttons">
                <button 
                  class="button is-link" 
                  :class="{ 'is-loading': processing }"
                  @click="send"
                  :disabled="!email.valid">
                  Send Reset Email
                </button>
                <router-link 
                  :to="{ name: 'Login' }"
                  class="button is-text">
                  Cancel
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <p class="has-text-centered">
          <router-link :to="{ name: 'Signup' }">
            Need to sign up instead?
          </router-link>
        </p>
        
      </div>
    </div>
  </div>
  <br><br><br>
</section>

</div>
</template>

<script>

import { api, parseError, field } from '@/modules/util'

export default {

  name: 'ResetPassword',
  data() {
    return {
      email: field(),
      success: false,
      processing: false
    }
  },

  methods: {
    async send() { 
      try {
        this.processing = true
        await api.post('/users/reset-password', { email: this.email.value })
        this.success = 'Instructions sent via email.'
        this.email = field()
        this.processing = false
      } catch (err) {
        this.error = parseError(err)
      }
    }
  }
}

</script>
